<template>
  <div>
    <v-card>
      <v-card-title>
        Multimedia
        <v-spacer></v-spacer>
        <v-text-field
          class="pr-4"
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-btn @click="createMultimedia" color="primary">Create Multimedia</v-btn>
      </v-card-title>

      <v-skeleton-loader v-if="firstLoad" :loading="loading" type="table"></v-skeleton-loader>
      <v-data-table
        :headers="headers"
        :items="multimedia"
        class="elevation-1"
        :loading="loading"
        v-show="!firstLoad"
        :search="search"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="$router.push({ path: `/mobile/multimedia/${item._id}/edit` })"
          >edit</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>


<script>
import RestResource from "../../services/dataServiceMobile";
const service = new RestResource();
export default {
  data() {
    return {
      multimedia: [],
      search: "",
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Start", value: "start", sortable: true },
        { text: "Expiry", value: "expiry", sortable: true },
        { text: "Action", value: "action", sortable: false }
      ],
      loading: true,
      firstLoad: true
    };
  },

  mounted() {
    this.fetchMultimedia();
  },

  methods: {
    fetchMultimedia() {
      service.fetchMultimedia().then(r => {
        this.multimedia = r.data.multimediaList;
        setTimeout(() => {
          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        }, 1000);
      });
    },
    createMultimedia() {
       this.$router.push({ name: "MobileMultimediaCreate" });
    }
  }
};
</script>